






































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        name: '',
        value: '',
        remark: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      const { data: configs } = await this.$http.get(`configs/${this.id}`)
      this.model = Object.assign(this.model, configs)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`configs/${this.id}`, this.model)
      } else {
        await this.$http.post('configs', this.model)
      }
      this.$router.push('/config/list')
      this.$message.success('保存成功')
    },
  },
})
